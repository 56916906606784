import Client from '@/services/Client'
const axios = new Client()

export const taxNote = {
  state: {
    taxNotes: [],
    statusTaxNote: {
      ISSUED: 'Emitido',
      AWAITING_ISSUANCE: 'Não emitido',
      CANCELED: 'Cancelado',
    },
  },
  getters: {
    getTaxNotes: state => state.taxNotes,
    getStatusTaxNote: state => state.statusTaxNote,
  },
  mutations: {
    setTaxNotes: (state, value) => state.taxNotes = value,
  },
  actions: {
    listTaxNotes({ commit , dispatch, getters}, url) {

      return new Promise((resolve, reject) => {
        if(!url.not_loading){
          commit('setShowSkeletonTable', true)
        }
        let uri = 'tax-note'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            if(!getters.getNoGetTotal && !url.not_loading){
              dispatch('getTotalToOrderHeader', {type: 'ORDER'})
            }
            if(!url.not_loading){
              commit('setTaxNotes', res.data)
            }
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    exportTaxNote({ commit , dispatch, getters}, data) {

      return new Promise((resolve, reject) => {
        let uri = 'tax-note/export'
        axios.post(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    async taxNoteUpdate({ commit , dispatch, getters}, data){
      if(data.data){
        for(let note in data.data){
          await axios.put('tax-note/'+data.data[note].id, {status:  data.status})
          .then(res => {
          })
          .catch(err =>  console.error(err))
        }
      }else{
       new Promise((resolve, reject) => {
          axios.put('tax-note/'+data.id, data)
            .then(res => {
              if(res.data) {
                resolve(res)
              } else reject(res)
            })
            .catch(err => reject(err))
        })
      }
      
    },
  }
}
