import Vuex from 'vuex'
import { user } from './modules/user'
import { product } from './modules/product'
import { provider } from './modules/provider'
import { customer } from './modules/customer'
import { saleSettings } from './modules/saleSettings'
import { sale } from './modules/sale'
import { utils } from './modules/utils'
import { report } from './modules/report'
import { movement } from './modules/movement'
import { frete } from './modules/frete'
import { order } from './modules/order'
import { seller } from './modules/seller'
import { permissionGroup } from './modules/permissionGroup'
import { role } from './modules/role'
import { taxNote } from './modules/taxNote'
import { freight } from './modules/freight'
import { integration } from './modules/integration'
import { company } from './modules/company'
import { register } from './modules/register'
import { ProductItem } from '@/store/modules/productItem';
import { boardTask } from '@/store/modules/boardTask';
import { payment } from '@/store/modules/payment';
import { pagarme } from '@/store/modules/pagarme';
import { movementAccountPlan } from '@/store/modules/movementAccountPlan';
import { pdv } from '@/store/modules/pdv';
import { exchanteReturnTransaction } from '@/store/modules/exchanteReturnTransaction';
import { carrier } from '@/store/modules/carrier';
import { colletion } from '@/store/modules/colletion';
import { unitsOfMenasurement } from '@/store/modules/unitsOfMenasurement';
import { grade } from '@/store/modules/grade';
import { bankAccount } from '@/store/modules/bankAccount';
import { transactionMovement } from '@/store/modules/transactionMovement';
import { paymentType } from '@/store/modules/paymentType';
import { people } from '@/store/modules/people';
import { marketing } from '@/store/modules/marketing';
import { movementReport } from '@/store/modules/movementReport';
import { category } from '@/store/modules/category';
import { banner } from '@/store/modules/banner';
import { orderStatus } from '@/store/modules/orderStatus';



import Vue from 'vue';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    product,
    provider,
    customer,
    saleSettings,
    sale,
    utils,
    report,
    movement,
    frete,
    order,
    seller,
    permissionGroup,
    role,
    taxNote,
    freight,
    integration,
    company,
    register,
    ProductItem,
    boardTask,
    payment,
    pagarme,
    movementAccountPlan,
    pdv,
    exchanteReturnTransaction,
    carrier,
    colletion,
    unitsOfMenasurement,
    grade,
    bankAccount,
    transactionMovement,
    paymentType,
    people,
    marketing,
    movementReport,
    category,
    banner,
    orderStatus
  }
})
