import Client from '@/services/Client'
const axios = new Client()

export const orderStatus = {
  state: {
    orderStatus: [],
  },
  getters: {
    getOrderStatus: state => state.orderStatus,
  },
  mutations: { 
    setOrderStatus: (state, value) => state.orderStatus = value,
  },
  actions: {
    listOrderStatus({commit}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'order-status'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          commit('setOrderStatus', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    updateOrderStatus({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.put('order-status/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    removeOrderStatus({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.delete('order-status/'+data.id)
          .then(res => {
            if(res.status == 204) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    crateOrderStatus({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post('order-status', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
