import axios from 'axios'
import VueNotificationsOptions from '.././util/notification.js'

axios.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const response = error.response
  
  if (response.status === 422) {
    for (const error in response.data) {
      VueNotificationsOptions.error({
        title: error? error:'Erro',
        message: response.data[error].join(', '),
        type: 'error',
        timeout: 5000,
        cb: undefined
      })
    }
  } else if (response.status === 401) {
   
    VueNotificationsOptions.error({
      title: 'Permissão',
      message: 'Não autorizado.',
      type: 'error',
      timeout: 3000,
      cb: undefined
    })
    localStorage.removeItem('access_token')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('roles')

  } else if (response.status === 500) {
    VueNotificationsOptions.error({
      title: 'Erro',
      message: 'Erro interno.',
      type: 'error',
      timeout: 3000,
      cb: undefined
    })
  }
  return Promise.reject(error)
})
