import axios from 'axios'

export default class Client {

  constructor (uri) {
    this.axios = axios
  }

  boot (uri) {
    this.axios.baseURL = process.env.VUE_APP_BASE_URL_API
  }

  login (uri, data) {
    return this.post(uri, {
      ...data,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      client_id: process.env.VUE_APP_CLIENT_ID,
      grant_type: 'password'
    })
    
    return fetch(process.env.VUE_APP_BASE_URL_API + '/api'+uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        client_id: process.env.VUE_APP_CLIENT_ID,
        grant_type: 'password'
      })
    }).then(response => response.json())
  }

  get (uri, data, isBlob) {
    this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')

    let configure = {
    }

    if(isBlob){
      configure.responseType = 'blob' // Indica que a resposta será binária (Blob)
    }

    return this.axios.get(process.env.VUE_APP_BASE_URL_API + '/api/' + uri, configure)
  }

  getExterenalApi (uri, data) {
    return this.axios.get(uri)
  }

  postFormData(uri, formData) {
    this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
     return this.axios.post(process.env.VUE_APP_BASE_URL_API + '/api/' + uri, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  }

  post (uri, data) {
    this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    return this.axios.post(process.env.VUE_APP_BASE_URL_API + '/api/' + uri, data)
  }

  postTeste (uri, data) {
    // this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    return this.axios.post(uri, data)
  }

  put (uri, data) {
    this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    return this.axios.put(process.env.VUE_APP_BASE_URL_API + '/api/' + uri, data)
  }

  delete (uri) {
    this.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    return this.axios.delete(process.env.VUE_APP_BASE_URL_API + '/api/' + uri)
  }
}
