<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
    style="border-radius: inherit; overflow: visible; z-index: 99 !important;"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <navbar-toggle-button @click.native="showSidebar"> </navbar-toggle-button>
        <b-img fluid :src="logo" alt="..." width="150px" />
      <slot name="mobile-right ">
        <a
          v-if="isMobile"
          href="#"
          class="nav-link pr-0"
          @click="() => $bvModal.show('modal-show-notification')"
          slot="title-container"
        >
          <i class="ni ni-bell-55 "></i>
        </a>

        <ul class="nav align-items-center d-md-none">
          <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
            <a slot="title-container" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span
                  class="font-weight-600 description mb-0 text-sm"
                  v-if="getUserLogged.id"
                >
                  {{ getUserLogged.name }}
                </span>
              </div>
            </a>
            <DropItemMenu />
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show default-bg-menu"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close text-white">
              <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
      </div>
    </div>

    <ShowNotification />
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import DropItemMenu from "@/views/Layout/DropItemMenu.vue";
import ShowNotification from "@/components/SidebarPlugin/ShowNotification.vue";

import { mapGetters } from "vuex";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    DropItemMenu,
    ShowNotification,
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  computed: {
    ...mapGetters(["getUserLogged", "isMobile"]),
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style></style>
